<template>
  <div class="d-flex mb-4 mt-4 justify-content-between">
    <div class="d-flex align-items-center">
      <div>
        <img class="avatar-50 border-radius-8" :src="item.image" :alt="item.title">
      </div>
      <div class="media-body ml-3">
        <h6 class="mb-0 text-black text-bold"><a href="#">{{item.title}}</a> <span>·</span> <small>{{ item.membersCount }} members</small></h6>
        <p class="mb-0"><small>{{ item.followers }} followers</small></p>
      </div>
    </div>
    <div>
      <b-button v-if="!item.isFollow && !item.isAdmin" variant="primary">
        <i class="ri-add-fill"></i>Join
      </b-button>
      <b-button v-if="item.isAdmin" class="w-100" variant="invite">
        Invite
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
