<template>
    <div>
      <div to="rightSidebar">
        <PeepChat />
      </div>
      <b-row>
        <b-col sm="12">
          <b-row class="m-0 p-0">
            <b-col lg="8">
              <div class="border-left border-right">
                <b-row class="">
                  <b-col class="peep-feed">
                    <div class="iq-card shadow-none mb-0">
                      <div class="iq-card-body p-0">
                          <div class="d-flex p-4 justify-content-around align-items-center">
                            <form action="#" class="searchbox search-box-user w-80 position-relative">
                              <input v-model="search" type="text" class="form-control search-input" placeholder="Search groups...">
                              <div class="search-group d-flex">
                                    <b-link href="javascript:void(0);">
                                      <i class="ri-search-line mr-2"></i>
                                    </b-link>
                                  </div>
                            </form>
                            <div class="d-flex align-items-center">
                              <img class="img-fluid cursor-pointer ml-3" src="@/assets/images/icon/create-group-icon.svg" title="Create a new group" alt="Create a new group" />
                            </div>
                          </div>
                          <hr>
                          <div class="pt-2 pb-0 pr-4 pl-4">
                            <h4 class="text-bold text-black mb-4">Your Groups</h4>
                            <ul class="todo-task-list p-0 m-0">
                              <li v-for="(item,index) in filteredItemsMy" :key="index">
                                <group-item :item="item" />
                              </li>
                            </ul>
                          </div>
                          <hr>
                          <div class="pt-2 pb-0 pr-4 pl-4">
                            <h4 class="text-bold text-black mb-4">Groups you've joined</h4>
                            <ul class="todo-task-list p-0 m-0">
                              <li v-for="(item,index) in filteredItemsJoined" :key="index">
                                <group-item :item="item" />
                              </li>
                            </ul>
                          </div>
                      </div>
                  </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col lg="4">
              <div class="pt-0 pb-0 pr-4 pl-4">
                <h4 class="text-bold text-black mb-4">Discover new Groups</h4>
                  <ul class="todo-task-list p-0 m-0">
                    <li v-for="(item,index) in filteredItemsDiscover" :key="index">
                      <group-item :item="item" />
                    </li>
                    <li>
                      <b-button variant="text" class="p-0">Show more</b-button>
                    </li>
                  </ul>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
</template>

<script>
import { socialvue } from '@/config/pluginInit'
import Groups from '@/FackApi/api/Groups'
import GroupItem from '@/components/homeiz/groups/GroupItem.vue'
import PeepChat from '@/components/homeiz/chatBars/PeepChat'

export default {
  name: 'AllGroups',
  components: {
    GroupItem,
    PeepChat
  },
  mounted () {
    socialvue.index()
  },
  computed: {
    filteredItemsMy () {
      return Object.values(this.groups).filter(item => {
        return item.title.toLowerCase().includes(this.search.toLowerCase()) && item.isAdmin
      })
    },
    filteredItemsDiscover () {
      return Object.values(this.groups).filter(item => {
        return item.title.toLowerCase().includes(this.search.toLowerCase()) && !item.isFollow && !item.isAdmin
      })
    },
    filteredItemsJoined () {
      return Object.values(this.groups).filter(item => {
        return item.title.toLowerCase().includes(this.search.toLowerCase()) && item.isFollow && !item.isAdmin
      })
    }
  },
  data () {
    return {
      groups: Groups,
      search: ''
    }
  },
  methods: {
    searchChanged (value) {
      this.search = value
    }
  }
}
</script>

<style scoped>
.search-group {
  position: absolute;
  font-size: 22px;
  right: 4px;
  top: 4px;
}
</style>
