import Group from '@/Model/Group'

const Groups = {
  1: new Group({ id: 1, title: 'MC2 Faves', isFollow: true, membersCount: 67, followers: 42 }),
  2: new Group({ id: 2, title: 'Shofarot', isFollow: true, membersCount: 64, followers: 11, image: require('@/assets/images/group/group-01.jpg') }),
  3: new Group({ id: 3, title: 'Climate change', isFollow: true, membersCount: 59, followers: 1, image: require('@/assets/images/group/group-02.jpg') }),
  4: new Group({ id: 4, title: 'Israel Journalists', isFollow: true, membersCount: 28, followers: '1.2K', image: require('@/assets/images/group/group-03.jpg') }),
  5: new Group({ id: 5, title: 'Real estate disruption', isFollow: true, membersCount: 21, followers: 1, image: require('@/assets/images/group/group-03.jpg') }),
  6: new Group({ id: 6, title: 'Real estate attorneys', isFollow: true, membersCount: 33, followers: 69, image: require('@/assets/images/group/group-04.jpg') }),
  7: new Group({ id: 7, title: 'Group 3', isAdmin: true, isFollow: false, membersCount: 33, followers: 69, image: require('@/assets/images/group/group-00.jpg') }),
  8: new Group({ id: 8, title: 'Group 4', isAdmin: true, isFollow: false, membersCount: 33, followers: 69, image: require('@/assets/images/group/group-01.jpg') }),
  9: new Group({ id: 9, title: 'Group 5', isAdmin: true, isFollow: false, membersCount: 33, followers: 69, image: require('@/assets/images/group/group-02.jpg') }),
  10: new Group({ id: 10, title: 'Group 6', isAdmin: true, isFollow: false, membersCount: 33, followers: 69, image: require('@/assets/images/group/group-03.jpg') }),
  11: new Group({ id: 11, title: 'Group 7', isAdmin: true, isFollow: false, membersCount: 33, followers: 69, image: require('@/assets/images/group/group-04.jpg') }),
  12: new Group({ id: 12, title: 'Group 8', isAdmin: true, isFollow: true, membersCount: 33, followers: 69, image: require('@/assets/images/group/group-01.jpg') }),
  13: new Group({ id: 13, title: 'Group 9', isFollow: false, membersCount: 33, followers: 69, image: require('@/assets/images/group/group-00.jpg') }),
  14: new Group({ id: 14, title: 'Group 10', isFollow: false, membersCount: 33, followers: 69, image: require('@/assets/images/group/group-01.jpg') }),
  15: new Group({ id: 15, title: 'Group 11', isFollow: false, membersCount: 33, followers: 69, image: require('@/assets/images/group/group-02.jpg') }),
  16: new Group({ id: 16, title: 'Group 12', isFollow: false, membersCount: 33, followers: 69, image: require('@/assets/images/group/group-03.jpg') }),
  17: new Group({ id: 17, title: 'Group 1', isFollow: false, membersCount: 33, followers: 69, image: require('@/assets/images/group/group-04.jpg') }),
  18: new Group({ id: 18, title: 'Group', isFollow: true, membersCount: 33, followers: 69, image: require('@/assets/images/group/group-01.jpg') })
}

export default Groups
