import { is } from 'ramda'
import User from './User'

export default class Group {
  constructor (group = {}) {
    this.id = is(Number, group.id) ? group.id : 0
    this.title = is(String, group.title) ? group.title : ''
    this.adminUser = is(Object, group.adminUser) ? group.adminUser : new User({ firtsName: 'Erez', lastName: 'Jonathan' })
    this.slug = is(String, group.slug) ? group.slug : this.createSlug()
    this.description = is(String, group.description) ? group.description : 'Group description text'
    this.rules = is(String, group.rules) ? group.rules : null
    this.image = is(String, group.image) ? group.image : require('@/assets/images/group/group-00.jpg')
    this.industryType = is(String, group.industryType) ? group.industryType : null
    this.location = is(String, group.location) ? group.location : null
    this.type = is(String, group.type) ? group.type : 'Public'
    this.isAdmin = is(Boolean, group.isAdmin) ? group.isAdmin : false
    this.isFollow = is(Boolean, group.isFollow) ? group.isFollow : true
    this.members = is(Object, group.members) ? group.members : []
    this.membersCount = is(Number, group.membersCount) ? group.membersCount : '42'
    this.followers = is(String, group.followers) ? group.followers : '6'
  }

  createSlug () {
    return this.title.split(' ').join('-').toLowerCase()
  }
}
